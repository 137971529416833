import React from 'react'
import Hero from '../components/Hero'
import CallToAction from '../components/CallToAction'

export default function careersPage() {
  return (
    <main className="careers">
      <Hero
        color="blue"
        heading="Careers at AdhereTech"
        text="Our success is rooted in the brilliant people and partners on our team"
      />
      <div className="page-section">
        <div className="page-section-content first-line">
          <p>
            AdhereTech is comprised of passionate individuals who share in our patient-first mission to provide
            continuous adherence support and connection to care to patients when they need it.
          </p>
        </div>
        <div className="page-section-content">
          <p>
            We come from the worlds of computer science, healthcare, engineering, patient support, data analysis,
            design, manufacturing, and business. We also work with numerous successful healthcare and technology
            executives as our advisors, investors, and Board of Directors.
          </p>
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content call-to-action-grid">
          <CallToAction
            headerText="Interested in joining our growing team?"
            content=""
            linkText="View job openings"
            linkUrl="https://boards.greenhouse.io/adheretech"
            color="blue"
            external
          />
        </div>
      </div>
    </main>
  )
}
